<template>
  <div class="banner animate__animated animate__fadeIn">
    <mainHeader :bbid="bbid" :bbmc="bbmc" :dropList="dropList"  :header_data="[{ title: '学习之旅', path: '/studyway' }, { title: $route.query.type } ]" />
    <div class="books">
      <div class="main">
        <el-carousel arrow="always" :interval="60000">
          <el-carousel-item v-for="(data, index) in list" :key="index">
            <div class="cardBox">
              <div class="box animate__animated animate__flipInX" :id="item.zid" :style="'animation-delay:' + (indexItem*0.2 + 0.5) + 's'" v-for="(item, indexItem) in data" :key="item.zid + '_' + indexItem">
                <div class="card">
                  <div class="img" :class="'bookBg' + (indexItem % 5 + 1) + ' ' + (item.zt ? 'studied':'studying')"></div>
                  <div class="bookRight">
                    <div class="bookMsg">
                      <div class="title">{{ item.zmc }}</div>
                      <div class="labels">
                        <div class="label">{{ item.cs }}词</div>
                        <div class="label">{{ item.gs }}关</div>
                      </div>
                      <div class="btn" @click="link(item)">开始学习</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import mainHeader from "@/components/student/header";
import { isHaveZ } from '@/api/student'
export default {
  components: {
    mainHeader
  },
  data() {
    return {
      bbid: 0,
      bbmc:'',
      list: [],
      linkList:[],
      dropList:[]
    }
  },
  created() {
    this.linkList= JSON.parse(sessionStorage.getItem('bbInfo'))
    this.dropList=this.linkList.map(item=>{
      return{
        id:item.id,
        mc:item.mc
      }
    })
  },
  methods: {
    link(data) {
      let jxInfo = JSON.parse(sessionStorage.getItem('jxInfo'))
      jxInfo.zid = data.zid
      jxInfo.zmc = data.zmc
      sessionStorage.setItem('jxInfo', JSON.stringify(jxInfo))
      const box = document.getElementById(data.zid)
      box.style.animationDelay = '0s'
      box.classList.add('animate__animated', 'animate__zoomOutRight')
      setTimeout(() => {
        this.$router.replace({ name: 'units', query: { type: this.$route.query.type, bbid: this.bbid, zid: data.zid,bbmc:this.bbmc} })
      }, 800)
    },
    getBooks(bbid) {
      isHaveZ({ bbid: bbid, xsid: this.$store.state.userInfo.yhid,bbmc:this.bbmc }).then(res => {
        if (res.success) {
          if (res.result.length) {
            let arr = []
            res.result.forEach((ele, index) => {
              if (!arr[parseInt(index / 6)]) {
                arr[parseInt(index / 6)] = []
              }
              arr[parseInt(index / 6)].push(ele)
            })
            this.list = arr
          }
        } else {
          this.$message.error(res.resultDesc)
        }
      })
    }
  },
  mounted(){
    this.bbid = Number(this.$route.query.bbid)
    this.bbmc=this.$route.query.bbmc
    this.getBooks(this.bbid)
  },
  watch: {
    '$route.query.bbid'(newV, oldV) {
      this.bbid = Number(newV)
      this.getBooks(this.bbid)
    },
  }
}
</script>
<style lang="scss" scoped>
.banner{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('../../../assets/images/student/booksbanner.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .main{
      width: 100%;
      position: absolute;
      top: 0px;
      bottom: 0px;
      .cardBox{
        position: absolute;
        top: 120px;
        bottom: 120px;
        left: 200px;
        right: 200px;
        .box{
          width: 33.3%;
          height: 49.9%;
          float: left;
          .card{
            background: #fff;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            margin: 10px;
            box-sizing: border-box;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
            border-radius: 24px;
            padding: 20px;
            .img{
              float: left;
              width: 50%;
              height: 100%;
              border: 4px solid #5990ff;
              box-sizing: border-box;
              border-top-left-radius: 20px;
              border-bottom-left-radius: 20px;
              background-size: 100% 100%;
              position: relative;
            }
            .img::after{
              width: 50px;
              height: 70px;
              position: absolute;
              background-size: 100% 100%;
              color: #fff;
              font-size: 14px;
              font-weight: bold;
              box-sizing: border-box;
              line-height: 16px;
            }
            .img.studying::after{
              content: '未完成';
              background: url('../../../assets/images/student/tip_studying.png') no-repeat;
              top: -8px;
              right: -20px;
              padding: 5px 20px;
            }
            .img.studied::after{
              content: '已学完';
              background: url('../../../assets/images/student/tip_studied.png') no-repeat;
              top: -6px;
              right: -28px;
              padding-top: 5px;
              padding-left: 10px;
              padding-right: 20px;
            }
            .img.bookBg1{
              background-image: url('../../../assets/images/student/book1.png');
            }
            .img.bookBg2{
              background-image: url('../../../assets/images/student/book2.png');
            }
            .img.bookBg3{
              background-image: url('../../../assets/images/student/book3.png');
            }
            .img.bookBg4{
              background-image: url('../../../assets/images/student/book4.png');
            }
            .img.bookBg5{
              background-image: url('../../../assets/images/student/book5.png');
            }
            .bookRight{
              float: left;
              width: 50%;
              height: 100%;
              position: relative;
              .bookMsg{
                width: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding: 1vh 1vw;
                box-sizing: border-box;
                .title{
                  color: #000;
                  font-size: 1.2vw;
                  text-align: center;
                  height: 8vh;
                }
                .labels{
                  overflow: hidden;
                  padding-top: 4vh;
                  .label{
                    float: left;
                    width: 40%;
                    background: #4783CB;
                    margin: 0 5%;
                    font-size: 1vw;
                    color: #fff;
                    border-radius: 4px;
                    text-align: center;
                  }
                }
                .btn{
                  background: #5990FF;
                  color: #fff;
                  width: 90%;
                  font-size: 1.1vw;
                  padding: 0.5vh 0;
                  text-align: center;
                  margin-left: 5%;
                  margin-top: 2vh;
                  border-radius: 20px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
}
</style>
<style lang="scss">
.books{
  .el-carousel{
    height: 100%;
    // 容器
    .el-carousel__container{
      height: 100%;
    }
    // 切换按钮
    .el-carousel__arrow{
      background-color: transparent;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      i{
        display: none;
      }
    }
    // 切换按钮-左
    .el-carousel__arrow--left{
      left: 50px;
      background-image: url('../../../assets/images/student/tabLeftIcon.png');
    }
    // 切换按钮-右
    .el-carousel__arrow--right{
      right: 50px;
      background-image: url('../../../assets/images/student/tabRightIcon.png');
    }
    // 轮播切换分页器
    .el-carousel__indicators{
      bottom: 60px;
    }
    .el-carousel__indicator{
      padding: 12px 15px;
      .el-carousel__button{
        width: 50px;
        height: 13px;
        border-radius: 6px;
        background: #fff;
        opacity: 1;
      }
    }
    .el-carousel__indicator.is-active{
      .el-carousel__button{
        background: #5990FF;
      }
    }
  }
}
</style>



